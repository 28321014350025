.snoozeWrapper {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;

  .heading {
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 10px;

    h1 {
      font-family: "Marker Regular";
      font-weight: 500;
      font-size: 60px;
      line-height: 64px;
      text-align: center;
    }

    p {
      font-family: "Outfit";
      color: #999999;
    }

    img {
      position: absolute;
      left: -100px;

      @media only screen and (max-width: 992px) {
        top: -70px;
        width: 155px;
      }

      @media only screen and (max-width: 768px) {
        display: none;
      }
    }
  }

  .cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;

    @media only screen and (max-width: 992px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media only screen and (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
    }

    .cardItem {
      padding: 2px;
      border-radius: 10px;
      background: linear-gradient(to bottom, #ffc000, #0c0c0c00);

      &[data-gradient-reverse="true"] {
        background: linear-gradient(to top, #ffc000, #0c0c0c00);
      }

      > div {
        background-color: #0c0c0c;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 24px 18px;
        height: 100%;
      }

      img {
        width: 90px;
        height: 90px;
        object-fit: contain;
        margin-bottom: 5px;
      }

      h4 {
        margin: 0;
        font-weight: bold;
        font-family: "Outfit";
      }

      p {
        color: #999999;
        font-family: 14px;
      }
    }
  }
}
