.exploreCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background: linear-gradient(to bottom, #ffc000, #0c0c0c00);
  padding: 50px;
  border-radius: 152.5px;
  text-align: center;
  padding: 1px;

  &[data-gradient-reverse="true"] {
    background: linear-gradient(to top, #ffc000, #0c0c0c00);
  }

  .content {
    height: 440px;
    width: 300px;
    background: #0c0c0c;
    color: white;
    padding: 3rem;
    border-radius: 152.5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 10px;

    h5 {
      font-weight: bold;
      font-size: 25px;
      margin: 0;
    }

    p {
      color: #999999;
      font-size: 19px;
    }

    h5,
    p {
      white-space: nowrap;
    }

    .imageContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      width: 128px;
      height: 128px;
      margin-bottom: 20px;
      background-color: #999999;
    }
  }

  &[data-active="true"] {
    .imageContainer {
      background-color: #ffb800 !important;
    }

    p {
      color: #ffc000;
      font-weight: bold;
    }
  }
}
