.navbar.bg-dark {
  background-color: #0c0c0c !important;
  border-bottom: 1px solid #dee2e6 !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  color: white;
  margin: 0;
}

a {
  text-decoration: none;
}

a:hover {
  color: white;
  text-decoration: none;
}

h1 {
  font-family: "Marker Regular";
  font-weight: 500;
  font-size: 60px;
  line-height: 64px;
  text-align: center;
}

.container {
  flex: 1;
}

form {
  display: flex;
  align-items: center;
  gap: 5px;
}

form input {
  background-color: rgba(0, 0, 0, 0.9);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  padding: 6px 12px;
  color: white;
}

form button {
  background-color: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  padding: 6px 20px;
  background-color: #ffb800;
  color: white;
  font-family: "Outfit", sans-serif;
  font-size: 16px;
  font-weight: 600;
}

form button:hover {
  background-color: black;
}
