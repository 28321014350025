.ourTeamsWrapper {
  display: flex;
  flex-direction: column;
  color: white;
  gap: 60px;

  .link {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .header {
    > h1 {
      font-family: "Marker Regular";
      font-weight: 500;
      font-size: 60px;
      line-height: 64px;
      color: #fff;
      text-align: center;
    }

    > p {
      font-family: "Outfit";
      color: #999999;
      font-family: 19px;
      text-align: center;
    }
  }

  .studios {
    display: flex;
    flex-direction: row;
    gap: 20px;

    @media only screen and (max-width: 992px) {
      flex-direction: column;
    }

    .studio {
      background-color: #121418;
      border-radius: 22px;
      padding: 42px 34px 24px 34px;
      display: flex;
      flex-direction: column;

      .info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;

        h3 {
          color: white;
          font-family: "Outfit";
          font-weight: bold;
          font-size: 40px;
          margin-bottom: 16px;
          text-align: left;
          white-space: nowrap;
        }

        p {
          color: #999999;
          font-size: 18px;
        }

        img {
          width: 117px;
          height: 117px;
        }

        @media only screen and (max-width: 768px) {
          h3 {
            font-size: 30px;
            line-height: 34px;
            margin-bottom: 8px;
          }

          p {
            font-size: 16px;
          }

          img {
            width: 80px;
            height: 80px;
          }
        }
      }

      strong {
        font-family: "Outfit";
        font-size: 20px;
        margin-bottom: 5px;
      }

      .recognitions {
        display: flex;
        flex-direction: column;
        margin-top: 20px;

        h5 {
          color: #ffc000;
          font-size: 20px;
          font-family: "Outfit";
        }

        ul {
          display: flex;
          gap: 50px;
          padding-left: 0;

          li {
            margin: 0;
            list-style: none;
            color: #999999;
          }
        }
      }
    }
  }

  .callingCreators {
    border: 2px solid #282440;
    background-color: #141220;
    border-radius: 22px;
    padding: 42px;

    .info {
      margin-bottom: 75px;
      display: flex;
      flex-direction: row;

      @media only screen and (max-width: 992px) {
        flex-direction: column;
      }

      > div {
        margin-right: 40px;
        h2 {
          font-family: "Marker Regular";
          font-weight: 500;
          font-size: 60px;
          line-height: 64px;
          color: #fff;
          margin-bottom: 20px;
        }

        p {
          font-family: "Outfit";
          font-size: 18px;
          line-height: 40px;
          color: #999999;
        }
      }

      img {
        object-fit: contain;
        width: 300px;
      }
    }
  }

  h3 {
    color: #ffc000;
    font-size: 60px;
    line-height: 64px;
    text-align: center;
    font-family: "Marker Regular";
  }

  img {
    width: 100%;
  }
}
