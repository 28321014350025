.exploreEcosystem {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 50px;

  h1 {
    font-family: "Marker Regular";
    font-weight: 500;
    font-size: 60px;
    line-height: 64px;
    color: #fff;
    text-align: center;
  }

  .cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 40px;
  }
}
