.faqWrapper {
  h1 {
    font-family: "Marker Regular";
    font-weight: 500;
    font-size: 60px;
    line-height: 64px;
    color: #fff;
    text-align: center;
    margin-bottom: 34px;
  }

  .accordion {
    .accordion-item {
      border: 0;
      background-color: transparent;
      margin-bottom: 16px;
    }

    .accordion-header {
      button {
        color: white;
        background-color: black;
        font-family: "Outfit";
        font-size: 20px;
        border: 1px solid #1f1f1f;
        border-radius: 10px;
        padding: 22px;

        &::after {
          background-image: url("../../../../assets/images/plus.svg");
        }
        &:not(.collapsed)::after {
          background-image: url("../../../../assets/images/minus.svg");
        }
      }
    }

    .accordion-body {
      margin-top: 10px;
      background-color: rgba(255, 255, 255, 0.03);
      border-radius: 10px;
      color: rgba(255, 255, 255, 0.5);
      border: 1px solid #1f1f1f;
      padding: 22px;
      line-height: 34px;
    }
  }
}
