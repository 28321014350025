.collectionWrapper {
  color: white;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  padding-top: 200px;

  @media only screen and (max-width: 1200px) {
    grid-template-columns: repeat(1, 1fr);
  }

  .mintCard {
    display: flex;
    justify-content: center;
    position: relative;
    height: 450px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;

    .backgroundImage {
      width: 100%;
      object-fit: contain;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      padding: 30px;
      gap: 24px;

      img {
        width: 100%;
      }

      h3 {
        font-family: "Outfit";
        font-size: 24px;
        font-weight: bold;
        line-height: 30px;
        text-align: left;
        margin-bottom: 0;
      }

      p {
        font-family: "Outfit", sans-serif;
        letter-spacing: 0em;
        text-align: center;
      }
    }

    &[data-active="true"] {
      background-image: url("../../../../assets/images/active-mint-item-background.svg");

      p {
        font-size: 18px;
        font-weight: 700;
        line-height: 23px;
      }
    }

    &[data-active="false"] {
      background-image: url("../../../../assets/images/inactive-mint-item-background.png");

      p {
        font-size: 16px;
        font-weight: 300;
        line-height: 20px;
      }
    }
  }
}
