.oddFuture {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 40px;

  h1 {
    font-family: "Marker Regular";
    font-weight: 500;
    font-size: 60px;
    line-height: 64px;
    color: #fff;
  }

  .futureoDDverseImage {
    background-color: #121418;
    padding: 118px 0px;
    border-radius: 22px;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
    }
  }
}
