.oddBoiWrapper {
  font-family: "Outfit";

  .heading {
    color: #ffc000;
    text-align: center;
    margin-bottom: 5rem;
    font-size: 32px;
  }

  .gifContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      object-fit: contain;
      border-radius: 100%;
    }
  }

  @media only screen and (max-width: 992px) {
    .row {
      row-gap: 24px;
    }

    img {
      max-width: 200px;
    }

    .gifContainer {
      width: 100%;
    }

    .contentHeading {
      text-align: center;
    }
  }

  div {
    display: flex;
    gap: 100px;

    .contentHeading {
      font-family: "Outfit";
      font-size: 40px;
      color: white;
      margin: 0;
      padding-bottom: 25px;
    }

    .description {
      color: #999999;
      font-family: "Outfit";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 40px;
      margin-bottom: 4rem;
    }

    .contentWrapper {
      display: flex;
      flex-direction: column;
      gap: 0;
    }
  }
}
