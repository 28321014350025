.btn {
  width: 100%;
  font-family: "Outfit", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  background-color: #ffb800;
  padding: 18px 38px;
  border-radius: 0px 12px 12px 12px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: #67511a;
  }
}

.link {
  display: flex;
}
