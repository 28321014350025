.hopInWrapper {
  font-family: "Outfit";

  .header {
    margin-bottom: 40px;
    display: block;
    > h1 {
      font-family: "Marker Regular";
      font-weight: 500;
      font-size: 60px;
      line-height: 64px;
      color: #fff;
      text-align: center;
    }
  }

  .heading {
    color: #ffc000;
    text-align: center;
    margin-bottom: 5rem;
    font-size: 32px;
  }

  img {
    width: 80%;
    object-fit: contain;
  }

  @media only screen and (max-width: 992px) {
    .row {
      row-gap: 24px;
      flex-direction: column-reverse;
    }

    img {
      max-width: 200px;
    }
  }

  div {
    display: flex;
    gap: 100px;

    .description {
      color: #999999;
      font-family: "Outfit";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 40px;
      margin-bottom: 4rem;
    }
    .contentWrapper {
      display: flex;
      flex-direction: column;
      gap: 0;
    }
  }
}
