body {
  margin: 0;
  font-family: "Outfit";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0c0c0c;
  overflow-x: hidden;
}

::selection {
  color: rgb(0, 0, 0);
  background-color: #ffb800;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  /*  */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ffb800;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(168, 109, 0);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
