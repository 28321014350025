.navigationBarWrapper {
  .navbar-toggler {
    display: none;
  }

  .bold {
    font-weight: bold;
    color: white !important;
  }

  .navbar-collapse {
    li {
      list-style: none;
      padding: 10px;
      text-align: center;
      color: rgb(255, 255, 255);
      display: flex;
      justify-content: center;

      &:hover {
        color: white;
      }
    }
  }

  @media only screen and (min-width: 992px) {
    .navbar-collapse {
      display: none;
    }
  }

  @media only screen and (max-width: 992px) {
    .navbar-nav {
      display: none;
    }

    .navbar-toggler {
      display: block;
    }
  }
}
