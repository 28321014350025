.footerWrapper {
  color: white;
  font-size: 16px;
  background-color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 16px;
  row-gap: 20px;
  text-align: center;

  .socials {
    display: flex;
    column-gap: 40px;

    img {
      width: 24px;
      height: 24px;
    }
  }

  .links {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 50px;
  }

  a {
    font-weight: 600;
    color: white;
    text-decoration: none;
  }
}
