.oddFluencersWrapper {
  padding: 50px 40px;
  position: relative;
  color: white;
  margin: 0 100px;

  @media only screen and (max-width: 1200px) {
    margin: 0 20px;
  }

  .gridTopRight {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
  }

  .gridBottomLeft {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  .content {
    background-color: #121418;
    border-radius: 22px;
    padding: 50px;
    z-index: 1000;
    display: flex;
    flex-direction: row;
    gap: 30px;

    img {
      width: 300px;
      object-fit: contain;
    }

    @media only screen and (max-width: 992px) {
      img {
        width: 50%;
      }

      flex-direction: column;
    }
  }

  .contentDescription {
    padding: 30px;
    font-family: "Outfit";

    .contentWrapper {
      display: flex;
      flex-direction: column;
      gap: 0;

      .comingSoon {
        font-weight: 300;
      }

      .contentHeading {
        font-family: "Outfit";
        font-size: 40px;
        color: white;
        margin: 0;
        padding-bottom: 25px;
        font-weight: bold;
      }

      .description {
        color: #999999;
        font-family: "Outfit";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 40px;
        margin-bottom: 4rem;
      }

      .highlighedText {
        color: #ffc000;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    margin: 0;
    padding: 5px;

    .content {
      padding: 40px !important;

      img {
        align-self: center;
      }

      .comingSoon,
      .contentHeading {
        text-align: center;
      }
    }

    .gridTopRight,
    .gridBottomLeft {
      display: none;
    }

    .contentDescription {
      padding: 0 !important;
    }

    .description {
      margin-bottom: 2rem !important;
    }
  }
}
