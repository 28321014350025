@font-face {
  font-family: "Marker Regular";
  src: local("Marker Regular"),
    url("../../assets/fonts/Marker-Regular.ttf") format("truetype");
}

.home {
  margin: 0 auto;
}

.sections {
  display: flex;
  flex-direction: column;
  row-gap: 150px;
  margin-bottom: 100px;
}

.backgroundDog {
  position: absolute;
  top: -70%;
  opacity: 0.25;
  z-index: -1;
  right: -8%;
  height: 100vw;

  @media only screen and (max-width: 992px) {
    display: none;
  }
}
