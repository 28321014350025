.communityWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 50px;
  color: #fff;

  .heading {
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 10px;

    h1 {
      font-family: "Marker Regular";
      font-weight: 500;
      font-size: 60px;
      line-height: 64px;
      text-align: center;
    }

    p {
      font-family: "Outfit";
      color: #ffc000;
    }

    img {
      position: absolute;
      left: -100px;

      @media only screen and (max-width: 992px) {
        top: -70px;
        width: 155px;
      }

      @media only screen and (max-width: 768px) {
        display: none;
      }
    }
  }

  .cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;

    @media only screen and (max-width: 992px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media only screen and (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
    }

    .card {
      background-color: #121418;
      border-radius: 22px;
      padding: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;
      text-align: center;

      img {
        width: 82px;
        height: 82px;
      }

      h4 {
        margin: 0;
        color: #ffc000;
        font-size: 20px;
      }

      p {
        color: #999999;
        font-size: 14px;
      }
    }
  }
}
