.economyWrapper {
  color: white;

  .heading {
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 10px;
    margin-bottom: 50px;

    h1 {
      font-family: "Marker Regular";
      font-weight: 500;
      font-size: 60px;
      line-height: 64px;
      text-align: center;
    }

    p {
      font-family: "Outfit";
      color: #999999;
    }
  }

  .card {
    background-color: #121418;
    border-radius: 30px;
    padding: 50px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    .content {
      width: 70%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      img {
        width: 300px;
        object-fit: contain;
        margin-bottom: 40px;
      }

      h4 {
        font-weight: bold;
        font-size: 24px;
      }

      p {
        line-height: 30px;
        color: #999999;
        font-size: 18px;
      }
    }
  }

  .pointsWrapper {
    display: flex;
    align-items: center;

    .points {
      display: flex;
      flex-direction: column;
      gap: 50px;
      padding: 0 0.7rem;

      .pointItem {
        display: flex;
        align-items: center;
        gap: 16px;

        .number {
          background-color: rgba(255, 255, 255, 0.1);
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
          min-width: 68px;
          min-height: 68px;
          border-radius: 100%;
        }

        .value {
          h6 {
            font-family: "Outfit";
            color: #ffc000;
            font-size: 20px;
          }
        }
      }
    }
  }
}
